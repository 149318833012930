<template>
  <div class="events">
    <div class="events__wrapper">
      <div
        class="events__header-background"
        :style="{
          backgroundImage: `url(${featuredEvent.header_image})`,
        }"
      ></div>
      <div class="events__main-container">
        <div v-if="featuredEvent" class="events__details-box">
          <span class="events__details-box_data"
            >{{ featuredEvent.dates }} | {{ featuredEvent.exhibition_type }}</span
          >
          <h2 class="events__details-box_title">{{ featuredEvent.title }}</h2>
          <span class="events__details-box_details">{{ featuredEvent.description }}</span>
          <BaseButton
            class="button button--xs button--white"
            text="MORE INFORMATION"
            @click="$router.push({ path: `/events/events/${featuredEvent.slug}` })"
          />
        </div>

        <div class="events__title-box">
          <h2 class="events__title-box_title">Events</h2>
          <div class="events__title-box_right">
            <div class="events__title-box_right-content">
              <Tag
                class="tag--public"
                :class="{ 'tag--selected': i.selected }"
                v-for="i in tagsData"
                :key="i.id"
                :value="i.title"
                @click="changeFilterValue(i)"
              />
            </div>
            <div class="divider divider--vertical"></div>
            <div class="events__title-box_right-content">
              <PublicSelect
                :items="sortData"
                :selected="cardData.sort"
                @input="sortEvents($event.id)"
              ></PublicSelect>
            </div>
          </div>
        </div>

        <div v-if="eventsList.length" class="events__events-box">
          <router-link
            v-for="(item, key) in eventsList"
            :key="`${key}-item`"
            :to="{ path: `/events/events/${item.slug}` }"
            v-slot="{ navigate }"
            custom
          >
            <div class="events__events-box_card" @click="navigate">
              <div
                class="image"
                :style="{
                  backgroundImage: `url(${item.search_image}`,
                }"
              >
                <div class="image__preview"><p>Click to View</p></div>
              </div>
              <div class="type">{{ item.type }}</div>
              <h3 class="title">{{ item.title }}</h3>
              <span class="data">{{ item.dates }}</span>
            </div>
          </router-link>
        </div>
        <h6 class="events__empty" v-else>No events</h6>

        <div class="events__button-box" v-if="showLoadMoreButton && hasMorePages">
          <BaseButton
            class="button button--xs button--white"
            text="LOAD MORE"
            @click.native="handleLoadMoreClick"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import BaseButton from "@/components/buttons/BaseButton.vue";
import PublicSelect from "@/components/inputs/PublicSelect.vue";
import Tag from "@/components/Tag.vue";

export default {
  components: { BaseButton, PublicSelect, Tag },

  data() {
    return {
      sortData: [
        { id: "newest_to_oldest", title: "Newest to Oldest" },
        { id: "oldest_to_newest", title: "Oldest to Newest" },
      ],
      filterBlock: {
        list: "events",
        type: "collection",
      },
      cardData: {
        sort: "newest_to_oldest",
        page: 1,
        events: "",
      },
      showLoadMoreButton: true,
      loading: false,
    };
  },
  head: {
    title: function () {
      return {
        inner: "Events browngrotta arts",
      };
    },
    meta: function () {
      return [
        {
          name: "description",
          itemprop: "description",
          content: "Events browngrotta arts",
          id: "meta:description",
        },
        {
          itemprop: "name",
          content: "Events browngrotta arts",
          id: "meta:name",
        },
        {
          itemprop: "image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "meta:image",
        },
        {
          property: "og:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "og:url",
        },
        {
          property: "og:type",
          content: "website",
          id: "og:type",
        },
        {
          property: "og:title",
          content: "Events browngrotta arts",
          id: "og:title",
        },
        {
          property: "og:image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "og:image",
        },
        {
          property: "og:image:alt",
          content: "Events browngrotta arts",
          id: "og:image:alt",
        },
        {
          property: "og:description",
          content: "Description Events browngrotta arts",
          id: "og:description",
        },
        {
          property: "og:site_name",
          content: process.env.VUE_APP_FRONTEND_URL,
          id: "og:site_name",
        },
        {
          property: "og:locale",
          content: "en_US",
          id: "og:locale",
        },
        {
          name: "twitter:card",
          content: "summary",
          id: "twitter:card",
        },
        {
          name: "twitter:site",
          content: "@BrowngrottaArts",
          id: "twitter:site",
        },
        {
          name: "twitter:creator",
          content: "@BrowngrottaArts",
          id: "twitter:creator",
        },
        {
          name: "twitter:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "twitter:url",
        },
        {
          name: "twitter:title",
          content: "Events browngrotta arts",
          id: "twitter:title",
        },
        {
          name: "twitter:description",
          content: "Description Events browngrotta arts",
          id: "twitter:description",
        },
        {
          name: "twitter:image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "twitter:image",
        },
        {
          name: "twitter:image:alt",
          content: "Events browngrotta arts",
          id: "twitter:image:alt",
        },
      ];
    },
  },
  async created() {
    await this.apiGetFiltersBlock(this.filterBlock);
    await this.apiGetEventsList(this.cardData);
    await this.apiGetFeaturedEvent();

    const breadcrumbs = [
      {
        title: "Home",
        to: "/",
      },
      {
        title: "Events",
        to: "/events/events",
        clickable: false,
      },
    ];
    this.setBreadcrumbs(breadcrumbs);
  },

  watch: {
    tagsData: {
      handler: function (val, oldVal) {
        let events = "";
        val.forEach(el => {
          if (el.selected) {
            events = el.key === "" ? "" : el.key;
          }
        });

        this.cardData.events = events;
        this.selectTypes(events);
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters("publicapi/events", {
      eventsList: "getEventsList",
      hasMorePages: "getHasMorePages",
      featuredEvent: "getFeaturedEvent",
      tagsData: "getFilters",
    }),
  },

  methods: {
    ...mapActions("publicapi/events", [
      "apiGetFeaturedEvent",
      "apiGetEventsList",
      "apiGetFiltersBlock",
    ]),
    ...mapMutations("publicapi/events", ["changeFilterValue"]),
    ...mapMutations(["setBreadcrumbs"]),

    async loadMore() {
      if (this.loading || !this.hasMorePages) return;
      this.loading = true;

      let data = { ...this.cardData, page: this.cardData.page + 1 };
      let success = await this.apiGetEventsList(data);
      if (success) {
        this.cardData = data;
      }
      this.loading = false;
    },

    handleLoadMoreClick() {
      this.showLoadMoreButton = false;
      this.loadMore();
      this.enableInfiniteScroll();
    },

    enableInfiniteScroll() {
      window.addEventListener("scroll", this.handleScroll);
    },

    handleScroll() {
      const scrollPosition = window.innerHeight + window.scrollY;
      const bottomPosition = document.documentElement.scrollHeight;

      if (scrollPosition >= bottomPosition - 100) {
        this.loadMore();
      }
    },

    async selectTypes(events) {
      let data = { ...this.cardData, page: 1, events: events };
      let success = await this.apiGetEventsList(data);
      if (success) {
        this.cardData = data;
      }
    },

    async sortEvents(id) {
      let data = { ...this.cardData, page: 1, sort: id };
      let success = await this.apiGetEventsList(data);
      if (success) {
        this.cardData = data;
      }
    },
  },
  mounted() {
    if (!this.showLoadMoreButton) {
      this.enableInfiniteScroll();
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss" scoped>
* {
  color: $white;
  font-size: 18px;
  line-height: 1.3;
  @media screen and (max-width: $xs) {
    font-size: 16px;
    line-height: 1.5;
  }
}

.events {
  display: flex;

  &__header {
    &-background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 58.4rem;
      z-index: 0;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      @media screen and (max-width: $xs) {
        height: 33.5rem;
      }

      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba($color: $lightBlack, $alpha: 0.45);
        z-index: 1;
      }
      &::after {
        content: "";
        bottom: -1px;
        position: absolute;
        width: 100%;
        height: 50%;
        background: linear-gradient(
          0deg,
          rgba($color: $lightBlack, $alpha: 1) 0%,
          rgba($color: $lightBlack, $alpha: 0) 100%
        );
        z-index: 1;
      }
    }
  }

  &__wrapper {
    position: relative;
    min-height: 100vh;
    min-width: 100vw;
  }

  &__main-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 40rem;
    padding: 0rem 10.5rem 23.5rem;
    z-index: 2;
    @media screen and (max-width: $md) {
      margin-top: 24.5rem;
    }
    @media screen and (max-width: $xs) {
      padding: 0rem 2rem 24rem;
    }
  }

  &__details-box {
    display: flex;
    flex-direction: column;
    width: 55%;
    @media screen and (max-width: $md) {
      width: 100%;
    }

    &_data {
      font-size: 16px;
      line-height: 1.6;
      text-transform: uppercase;
      @media screen and (max-width: $xs) {
        font-size: 14px;
      }
    }

    &_title {
      font-size: 40px;
      line-height: 1.5;
      margin-bottom: 17px;
      @media screen and (max-width: $xs) {
        font-size: 35px;
        line-height: 1.23;
        margin-bottom: 25px;
      }
    }

    .button {
      margin-top: 22px;
      @media screen and (max-width: $xs) {
        margin-top: 35px;
      }
    }
  }

  &__title-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 9rem 0rem 6rem;
    @media screen and (max-width: $lg) {
      flex-direction: column;
      align-items: flex-start;
    }
    @media screen and (max-width: $xs) {
      margin: 8.5rem 0rem 2rem;
    }
    &_right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      @media screen and (max-width: $md) {
        width: 100%;
        flex-direction: column;
        .divider {
          display: none;
        }
        &-content {
          &:last-of-type {
            margin-left: 10px;
          }
        }
      }
      &-content {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        & > * {
          margin-bottom: 10px;
        }
      }
      @media screen and (max-width: $lg) {
        margin-top: 30px;
        margin-left: -10px;
      }
      @media screen and (max-width: $xs) {
        &-content {
          flex-wrap: wrap;
        }
      }
    }
    &_title {
      font-size: 5.2rem;
      line-height: 1.25;
      @media screen and (max-width: $md) {
        font-size: 35px;
      }
    }
  }
  &__empty {
    font-size: 20px;
    font-weight: 600;
    padding: 50px 10px;
    text-align: center;
    text-transform: uppercase;
  }

  &__events-box {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, minmax(30%, 1fr));
    grid-column-gap: 3rem;
    grid-row-gap: 7rem;
    @media screen and (max-width: $md) {
      grid-template-columns: repeat(2, minmax(30%, 1fr));
    }
    @media screen and (max-width: $xs) {
      grid-template-columns: minmax(30%, 1fr);
      grid-row-gap: 7rem;
    }

    &_card {
      display: flex;
      flex-direction: column;
      cursor: pointer;

      .image {
        position: relative;
        height: 27vw;
        width: 100%;
        object-fit: cover;
        background-size: cover;
        background-position: center;
        border-radius: 4px;
        @media screen and (max-width: $md) {
          height: 40vw;
        }
        @media screen and (max-width: $xs) {
          height: 90vw;
        }

        &__preview {
          position: absolute;
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-self: center;
          opacity: 0;
          border-radius: 4px;
          transition: all 0.3s ease;

          p {
            width: 100%;
            text-align: center;
            font-size: 3rem;
            line-height: 1.67;
            letter-spacing: -1px;
          }
        }

        &:hover {
          .image__preview {
            opacity: 1;
            background-color: rgba($color: $blue, $alpha: 0.83);
          }
        }
      }

      .type {
        display: flex;
        justify-content: center;
        align-content: center;
        text-transform: uppercase;
        border: 1px solid $white;
        border-radius: 4px;
        width: max-content;
        min-width: 9.7rem;
        margin-top: 2.6rem;
        font-size: 1.4rem;
        line-height: 2.5rem;
        padding: 0.1rem 1rem;
      }

      .title {
        font-size: 2.4rem;
        line-height: 3.1rem;
        margin-top: 1rem;
      }

      .data {
        margin-top: 1.2rem;
        text-transform: uppercase;
        @media screen and (max-width: $xs) {
          margin-top: 1rem;
          font-size: 1.4rem;
          line-height: 2.5rem;
        }
      }
    }
  }

  &__button-box {
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%;
    margin-top: 7rem;

    .button {
      min-width: 180px;
    }
  }
}
</style>

